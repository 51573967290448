@import "../../scss/variables";
@import "../../scss/utils";

.faq-section {
  .accordion {
    gap: 1rem !important;
    width: 800px;
    margin: 0 auto;
    @include for-tablet {
      width: 100%;
    }
    .accordion-item {
      margin-bottom: 1rem;
      border-radius: $border-radius;
      border: none;
      background-color: $primary-light;
      .accordion-header {
        background-color: transparent;
        .accordion-button {
          background-color: transparent;
          color: $primary;
          font-weight: 500;
          &.collapsed {
            background-color: transparent;
            color: $text;
          }
          &:focus {
            box-shadow: none;
            border: none;
          }
        }
      }
      .accordion-body {
        padding: 0 1rem 1rem 1rem;
      }
    }
  }
}
