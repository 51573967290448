@import "../../scss/utils";
@import "../../scss/variables";

button.custom-button {
  padding: 0.5rem 1rem;
  border-radius: $border-radius;
  // font-size: 16px;
  transition-duration: 0.3s;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  // @include for-mobile {
  //   font-size: 14px;
  // }
  // @include for-extra-small {
  //   font-size: 12px;
  // }
  span {
    text-transform: capitalize;
  }
  &:hover {
    transition-duration: 0.3s;
  }
  &:disabled {
    cursor: crosshair;
  }
  &.primary {
    background-color: $primary;
    span,
    .icon {
      color: $white;
    }
    &:hover {
      background-color: darken($primary, 10%);
    }
  }
}
