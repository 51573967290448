@import "../../scss/variables";
@import "../../scss/utils";

.services-section {
  .services {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    @include for-tablet {
      grid-template-columns: repeat(2, 1fr);
    }
    @include for-mobile {
      grid-template-columns: repeat(1, 1fr);
      gap: 0;
    }
  }
}
