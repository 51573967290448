@import "./utils";
@import "./variables";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  //   transition-duration: $transition-duration;
  list-style-type: none;
  border: none;
  outline: none;
  // border: 1px solid $primary;
}

:root {
  font-size: 16px;
  @include for-mobile {
    font-size: 14px;
  }
  @include for-extra-small {
    font-size: 12px;
  }
  @include for-extra-large {
    font-size: 18px;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
  font-family: "Inter", sans-serif;
  color: $text !important;
  scroll-behavior: smooth;
  background-color: $secondary;
  @include for-mobile {
    font-size: 14px;
  }
  @include for-extra-small {
    font-size: 12px;
  }
  @include for-extra-large {
    font-size: 18px;
  }
  // @include for-mobile {
  //   font-size: 12px;
  // }
  /* scrollbar ******************************* */

  &::-webkit-scrollbar {
    width: 0.6rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: transparent;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    outline: 1px solid slategrey;
  }

  /* end scrollbar ******************************* */
}

textarea {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
}

p {
  color: $text;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.con {
  width: 1100px;
  margin: 0 auto;
  @include for-extra-large {
    width: 1300px;
  }
  @include for-tablet {
    width: 90%;
  }
  @include for-mobile {
    width: 90%;
  }
}

form {
  .flex {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    @include for-mobile {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }
  .button-flex {
    display: flex;
    gap: 0.5rem;
  }
  .field {
    width: 100%;
    height: auto;
    text-align: left;
    margin-bottom: 1rem;
    label {
      display: block;
      font-size: 1rem;
      margin-bottom: 0.1rem;
      color: $text;
      text-transform: lowercase;
      &::first-letter {
        text-transform: uppercase;
      }
      &.bold {
        font-weight: 600;
        color: $light-text;
      }
    }
    input,
    select {
      width: 100%;
      height: 45px;
      border-radius: $border-radius;
      padding: 0 0.7rem;
      font-size: 1rem;
      background-color: $white;
    }
    textarea {
      width: 100%;
      height: 300px;
      border-radius: $border-radius;
      padding: 0.7rem;
      font-size: 1rem;
      background-color: $white;
      transition-duration: $transition-duration;
    }
    input,
    select {
      &:focus {
        border-color: $primary;
        transition-duration: $transition-duration;
      }
    }
    &.attachments {
      width: 100%;
      border-radius: $border-radius;
      padding: 1rem;
      font-size: 1rem;
      background-color: $white;
      border: 2px dashed $light-text;
      > input {
        display: none;
      }
      label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: $light-text;
        cursor: pointer;
        &:hover {
          color: $text;
        }
      }

      > p {
        width: 100%;
        border-radius: $border-radius;
        padding: 0.5rem;
        font-size: 0.9rem;
        background-color: $primary-light;
        color: $primary;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 0.3rem;
        &:first-of-type {
          margin-top: 1rem;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

section {
  padding: 4rem 0;
  @include for-mobile {
    padding: 2rem 0;
  }
  .section-title {
    margin-bottom: 2rem;
    text-align: center;
    h2 {
      font-size: 1.5rem;
      color: $primary;
      font-weight: 600;
      text-transform: capitalize;
      text-align: center;
    }
    p {
      width: 50%;
      font-size: 1rem;
      color: $text;
      text-align: center;
      margin: 0 auto;
      @include for-tablet {
        width: 70%;
      }
      @include for-mobile {
        width: 100%;
      }
    }
  }
}
