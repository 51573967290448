@import "../../scss/variables";
@import "../../scss/utils";

.calculator {
  .top {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    margin-bottom: 2rem;
    @include for-tablet {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
    .amount {
      h3 {
        font-size: 0.9rem;
        font-weight: 600;
        color: $light-text;
        text-transform: capitalize;
        margin: 0;
        white-space: nowrap;
      }
      p {
        font-size: 2em;
        font-weight: 600;
        color: $text;
      }
      &:last-child {
        text-align: right;
        @include for-tablet {
          text-align: center;
        }
      }
      @include for-tablet {
        text-align: center;
      }
    }
    .house {
      text-align: center;
      img {
        height: 100px;
        @include for-tablet {
          margin: 0 auto;
        }
      }
    }
  }

  .range-con {
    .edges {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    input[type="range"] {
      -webkit-appearance: none;
      margin: 18px 0;
      width: 100%;
      background-color: $secondary;
      margin: 0;
      padding: 0;
    }
    input[type="range"]:focus {
      outline: none;
    }
    input[type="range"]::-webkit-slider-runnable-track {
      width: 100%;
      height: 5px;
      cursor: pointer;
      background: $primary;
      border-radius: 1.3px;
    }
    input[type="range"]::-webkit-slider-thumb {
      height: 20px;
      width: 20px;
      border: 3px solid $secondary;
      border-radius: 50%;
      background: $primary;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -14px;
    }
    input[type="range"]:focus::-webkit-slider-runnable-track {
      background: $primary;
    }
    input[type="range"]::-moz-range-track {
      width: 100%;
      height: 5px;
      cursor: pointer;
      background: $primary;
      border-radius: 1.3px;
    }
    input[type="range"]::-moz-range-thumb {
      height: 20px;
      width: 20px;
      border: 3px solid $secondary;
      border-radius: 50%;
      background: $primary;
      cursor: pointer;
    }
    input[type="range"]::-ms-track {
      width: 100%;
      height: 5px;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      border-width: 16px 0;
      color: transparent;
    }
    input[type="range"]::-ms-fill-lower {
      background: $primary;
      border-radius: 2.6px;
    }
    input[type="range"]::-ms-fill-upper {
      background: $primary;
      border-radius: 2.6px;
    }
    input[type="range"]::-ms-thumb {
      height: 20px;
      width: 20px;
      border: 3px solid $secondary;
      border-radius: 50%;
      background: $primary;
      cursor: pointer;
    }
    input[type="range"]:focus::-ms-fill-lower {
      background: $primary;
    }
    input[type="range"]:focus::-ms-fill-upper {
      background: $primary;
    }
  }
}
