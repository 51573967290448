@import "../../scss/variables";
@import "../../scss/utils";

.contact-us-section {
  .con {
    > .flex {
      display: flex;
      gap: 2rem;
      @include for-tablet {
        gap: 1rem;
      }
      @include for-mobile {
        flex-direction: column;
      }
      .contact-form {
        width: 60%;
        height: 100%;
        background-color: $primary-light;
        border-radius: $border-radius;
        padding: 2rem;
        @include for-tablet {
          padding: 1rem;
        }
        @include for-mobile {
          width: 100%;
        }
        h3 {
          font-size: 1rem;
          font-weight: 600;
          color: $text;
          margin-bottom: 1rem;
        }
      }
      .contact-info {
        width: 40%;
        min-height: 100%;
        background-color: $black;
        border-radius: $border-radius;
        padding: 2rem;
        @include for-tablet {
          padding: 1rem;
        }
        @include for-mobile {
          width: 100%;
        }
        h3 {
          font-size: 1.2rem;
          font-weight: 600;
          color: $white;
          margin: 0;
        }
        p {
          font-size: 1rem;
          color: $white;
        }
        .details {
          margin-top: 2rem;
          .sub {
            margin-bottom: 2rem;
            display: flex;
            gap: 1rem;
            .icon {
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              background-color: hsl(0, 0%, 10%);
              margin-bottom: 1rem;
              color: $white;
              font-size: 1rem;
              @include for-mobile {
                width: 30px;
                height: 30px;
                img {
                  width: 15px;
                }
              }
            }
            .content {
              h4 {
                font-size: 0.9rem;
                font-weight: 600;
                color: hsl(0, 0%, 50%);
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
