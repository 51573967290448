@import "./scss/variables";
@import "./scss/utils";

.App {
  min-height: 100vh;
  background-color: $secondary;
  .page-content {
    margin-top: 70px;
  }
  .calculator-section {
    .con {
      display: flex;
      align-items: center;
      gap: 2rem;
      background-color: $primary;
      padding: 3rem;
      border-radius: $border-radius;
      @include for-tablet {
        flex-direction: column;
        padding: 1rem;
      }
      .right {
        width: 40%;
        @include for-tablet {
          width: 100%;
        }
        h2 {
          font-size: 2rem;
          font-weight: 700;
          color: $white;
          text-transform: capitalize;
        }
        p {
          color: $white;
        }
      }
      .left {
        width: 60%;
        background-color: $secondary;
        padding: 1rem;
        border-radius: $border-radius;
        @include for-tablet {
          width: 100%;
        }
      }
    }
  }
}
