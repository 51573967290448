@import "../../scss/variables";
@import "../../scss/utils";

.hero {
  display: flex;
  gap: 3rem;
  align-items: center;
  padding: 1rem 1rem 1rem calc(calc(100vw - 1100px) / 2);
  @include for-tablet {
    padding: 1rem 5vw;
    flex-direction: column;
  }
  .left {
    flex-grow: 1;
    @include for-tablet {
      padding-top: 3rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-content: center;
    }
    @include for-mobile {
      padding-top: 0;
    }
    h1 {
      font-size: 2.5rem;
      color: $text;
      font-weight: 700;
      margin-bottom: 1rem;
      span {
        background: linear-gradient(
          91.89deg,
          $primary 67.57%,
          $light-green 87.45%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
      @include for-mobile {
        font-size: 1.8rem;
      }
    }
    p {
      font-size: 1rem;
      color: $text;
      margin-bottom: 2rem;
      @include for-tablet {
        margin: 0 auto;
        margin-bottom: 2rem;
        width: 50%;
      }
      @include for-mobile {
        width: 100%;
      }
    }
    button {
      @include for-tablet {
        width: max-content;
        margin: 0 auto;
      }
    }
  }
  .right {
    width: 50vw;
    @include for-tablet {
      width: 70%;
    }
    @include for-mobile {
      width: 100%;
    }
    img {
      width: 100%;
      border-radius: $border-radius;
      max-height: 70vh;
      @include for-mobile {
      }
    }
  }
}
