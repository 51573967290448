@import "../../scss/variables";
@import "../../scss/utils";

footer.footer {
  margin-bottom: 2rem;
  color: $white;
  .con {
    background-color: $black;
    border-radius: $border-radius;
    padding: 2rem;
    @include for-tablet {
      padding: 2rem 1rem;
    }
    @include for-mobile {
      padding: 2rem 1rem;
    }
    .top {
      display: flex;
      gap: 1rem;
      @include for-mobile {
        flex-direction: column;
      }
      .left {
        width: 40%;
        @include for-tablet {
          width: 30%;
        }
        @include for-mobile {
          width: 100%;
        }
        .logo {
          width: 100px;
          margin-bottom: 1rem;
        }
        p {
          color: $white;
          font-size: 0.9rem;
          width: 70%;
          @include for-tablet {
            width: 100%;
          }
        }
      }
      .right {
        width: 60%;
        @include for-tablet {
          width: 70%;
        }
        nav {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 1rem;
          @include for-mobile {
            grid-template-columns: repeat(1, 1fr);
          }
          .sub {
            h4 {
              font-size: 0.9rem;
              font-weight: 600;
              color: hsl(0, 0%, 50%);
              margin: 0;
              margin-bottom: 1rem;
            }
            ul {
              li {
                margin-bottom: 0.5rem;
                a {
                  color: $white;
                  cursor: pointer;
                  text-decoration: none;
                  font-size: 0.9rem;
                  &::first-letter {
                    text-transform: uppercase;
                  }
                  &:hover {
                    text-decoration: underline;
                    color: $white;
                  }
                }
                display: flex;
                gap: 0.5rem;
                .icon {
                  color: hsl(0, 0%, 50%);
                  font-size: 0.9rem;
                }
                p {
                  color: $white;
                  font-size: 0.9rem;
                }
              }
            }
          }
        }
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.5rem;
      color: $light-text;
      border-top: 1px solid hsl(0, 0%, 20%);
      margin-top: 1rem;
      padding-top: 1rem;
      @include for-mobile {
        flex-direction: column;
        align-items: flex-start;
      }
      p {
        color: $white;
        font-size: 0.9rem;
      }
      nav {
        @include for-mobile {
          margin-top: 1rem;
        }
        ul {
          display: flex;
          li {
            padding: 0 1rem;
            border-right: 1px solid hsl(0, 0%, 30%);
            a {
              color: $white;
              cursor: pointer;
              text-decoration: none;
              font-size: 0.9rem;
              &::first-letter {
                text-transform: uppercase;
              }
              &:hover {
                text-decoration: underline;
                color: $white;
              }
            }
            &:last-child {
              border: none;
            }
            @include for-mobile {
              &:first-child {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }
}
