@import "../../scss/variables";
@import "../../scss/utils";

.service-card {
  border-radius: $border-radius;
  padding: 1rem;
  .icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    background-color: $primary-light;
    margin-bottom: 1rem;
    img {
      width: 20px;
    }
    @include for-mobile {
      width: 30px;
      height: 30px;
      img {
        width: 15px;
      }
    }
  }
  h3 {
    font-size: 1.1rem;
    color: $primary;
    font-weight: 600;
    margin-bottom: 0.3rem;
  }
  p {
    font-size: 1rem;
    color: $text;
  }
  &:nth-child(even) {
    background-color: $primary-light;
    .icon {
      background-color: $secondary;
    }
  }
  @include for-tablet {
    &:nth-child(3) {
      background-color: $primary-light;
      .icon {
        background-color: $secondary;
      }
    }
    &:nth-child(4) {
      background-color: transparent;
      .icon {
        background-color: $primary-light;
      }
    }
  }
  @include for-mobile {
    &:nth-child(4) {
      background-color: $primary-light;
      .icon {
        background-color: $secondary;
      }
    }
    &:nth-child(3) {
      background-color: transparent;
      .icon {
        background-color: $primary-light;
      }
    }
  }
}
