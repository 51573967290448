@import "../../scss/variables";
@import "../../scss/utils";

header.header {
  width: 100vw;
  padding: 1rem 0;
  position: fixed;
  left: 0;
  top: 0;
  background-color: $secondary;
  z-index: 9;
  @include for-mobile {
    padding: 0.5rem 0;
  }
  .con {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      width: auto;
      height: 30px;
      z-index: 4;
      @include for-tablet {
        height: 25px;
      }
      @include for-mobile {
        height: 20px;
      }
    }
    nav {
      ul {
        display: flex;
        align-items: center;
        gap: 1rem;
        .link {
          color: $text;
          font-size: 1rem;
          cursor: pointer;
          position: relative;
          text-decoration: none;
          &::after {
            content: "";
            display: block;
            width: 0;
            height: 2px;
            background-color: $text;
            position: absolute;
            left: 0;
            top: 110%;
            transition-duration: $transition-duration;
          }
          &:hover {
            &::after {
              transition-duration: $transition-duration;
              width: 10px;
            }
          }
        }
      }
      @include for-mobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 0vw;
        height: 100vh;
        background-color: $secondary;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-items: center;
        overflow-x: hidden;
        transition-duration: $transition-duration;
        &.open {
          width: 100vw;
          transition-duration: $transition-duration;
        }
        ul {
          min-width: 100vw;
          display: flex;
          align-items: center;
          gap: 1rem;
          flex-direction: column;
          margin: 0 auto;
          a {
            text-align: center;
            font-size: 1.2rem;
            font-weight: 600;
          }
        }
      }
    }
    button {
      @include for-mobile {
        display: none;
      }
    }
    .toggler {
      display: none;
      @include for-mobile {
        display: block;
        position: relative;
        width: 30px;
        height: 16px;
        z-index: 4;
        span {
          display: block;
          border-radius: 5px;
          height: 2px;
          width: 100%;
          background-color: $text;
          position: absolute;
          right: 0;
          transition-duration: $transition-duration;
          &:first-child {
            top: 0;
          }
          &:nth-child(2) {
            width: 50%;
            top: 50%;
            transform: translateY(-50%);
          }
          &:last-child {
            bottom: 0;
            width: 75%;
          }
        }
      }
      &.open {
        @include for-mobile {
          display: block;
          position: relative;
          width: 30px;
          height: 16px;
          span {
            display: block;
            border-radius: 5px;
            height: 2px;
            width: 100%;
            background-color: $text;
            position: absolute;
            right: 0;
            transition-duration: $transition-duration;
            &:first-child {
              width: 70%;
              top: 50%;
              transform: rotateZ(45deg);
            }
            &:nth-child(2) {
              display: none;
            }
            &:last-child {
              width: 70%;
              top: 50%;
              transform: rotateZ(-45deg);
            }
          }
        }
      }
    }
  }
}
