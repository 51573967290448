@mixin for-mobile {
  @media only screen and (max-width: 767px) {
    @content;
  }
}
@mixin for-extra-small {
  @media only screen and (max-width: 360px) {
    @content;
  }
}

@mixin for-tablet {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin for-extra-large {
  @media screen and (min-width: 2000px) {
    @content;
  }
}
