@import "../../scss/variables";
@import "../../scss/utils";

.join-us-today {
  .cta {
    background-color: $white;
    background-image: url("../../assets//vector/cta-vector.svg");
    background-repeat: no-repeat;
    background-position: right;
    padding: 3rem;
    border-radius: $border-radius;
    @include for-mobile {
      background-image: none;
    }
    h1 {
      font-size: 1.5rem;
      color: $primary;
      font-weight: 600;
      margin-bottom: 0.3rem;
    }
    p {
      font-size: 1rem;
      color: $text;
      margin-bottom: 2rem;
      @include for-tablet {
        width: 50%;
      }
      @include for-mobile {
        width: 100%;
      }
    }
  }
}
