$primary: #113535;
$primary-light: hsla(180, 51%, 14%, 0.07);
$secondary: #ebe5db;
$light-green: #137366;
$white: #fff;
$black: #000;
$text: $black;
$light-text: rgba(0, 0, 0, 0.5);
$border-radius: 5px;
$transition-duration: 0.3s;
